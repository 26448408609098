import * as Styled from "./titleWithParagraphStyles";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import React from "react";
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlhref,
} from "../../utils";

const HeaderImageWithText = ({ sectionData, location }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.Paragraph>{children}</Styled.Paragraph>
      ),
    },
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <Styled.MainDiv>
      <Styled.Title>{sectionData?.title}</Styled.Title>
      {sectionData?.contentDetails?.raw &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
      <Styled.ButtonContainer>
        <Styled.Button
          href={
            isExternalUrlhref(sectionData?.subText)
              ? sectionData?.subText
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + sectionData?.subText
                )
          }
          target={isExternalUrl(sectionData?.subText) ? "_blank" : "_self"}
        >
          Apply Here
        </Styled.Button>
      </Styled.ButtonContainer>
    </Styled.MainDiv>
  );
};

export default HeaderImageWithText;
